import React, { useEffect, useState } from 'react';

import { Box, Button, Checkbox, Stack, ToggleButton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getHistories, getLog, setHistoryAction } from '../../api/bot.api';
import { displayWithNonZeroDecimals, formatDateTime, shortenAddress } from '../../libs/utils';
import LogModal from './LogModal';

interface HistoryInterface {
    _id: string;
    commandType: string;
    data: {
        positionMint: string;
        oldPositionMint?: string;
        feesToRebalance: boolean;
        symbolA: string;
        symbolB: string;
        totalYield?: number;
        balance?: number;
        wastedFee?: number;
        wastedFeeUsd?: number;
        converted?: string;
        walletAddress: string;
        poolBalance: number;
        afterPoolBalance: number;
        walletBalance: number;
        afterWalletBalance?: number;
        beforeTotalBalance?: number;
        afterTotalBalance?: number;
        difference?: number;
        swapMinus?: number;
        prices?: { [key: string]: string };
        rebalance?: { [key: string]: string };
    },
    status: string;
    createdAt: string;
}

const commandList = ['collect', 'rebalance', 'close'];
const statusList = ['doing', 'done', 'failed'];
const textColor: { [key: string]: string } = {
    'doing': 'error',
    'done': 'info',
    'failed': 'secondary'
}

export default function HistoryContainer() {
    const [histories, setHistories] = React.useState<HistoryInterface[]>([]);
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const [commands, setCommands] = useState<string[]>(['rebalance']);
    const [statuses, setStatuses] = useState<string[]>(['done', 'doing']);
    const [logModalOpen, setLogModalOpen] = useState<boolean>(false);
    const [logData, setLogData] = useState<string>('');
    const [modalCaption, setModalCaption] = useState<string>('');
    const [converted, setConverted] = useState<number>(0);

    const dispatch = useDispatch();

    const updateHistories = async () => {
        const res = await getHistories({});
        if(res.success){
            setHistories(res.data);
            // Calculate total converted value
            const totalConverted = res.data.reduce((sum: number, history: HistoryInterface) => {
                const convertedValue = history.data?.converted ? parseFloat(history.data.converted) : 0;
                return sum + convertedValue;
            }, 0);

            // Set the total converted value to state
            setConverted(totalConverted);
        }
    }

    useEffect(() => {
        updateHistories();
        const timer = setInterval(() => {
            updateHistories();
        }, 5000)
        return () => {
            clearInterval(timer);
        }
    }, [dispatch])
    
    const handleAllChecked = (checked: boolean) => {
        if(checked){
            const allIds = histories
                .filter(history => commands.includes(history.commandType) && statuses.includes(history.status))
                .map(history => history._id);
            setCheckedList(allIds);
        }else{
            setCheckedList([]);
        }
    };

    const handleChecked = (_id: string, checked: boolean) => {
        if(checked){
            setCheckedList([...checkedList, _id]);
        }else{
            setCheckedList(checkedList.filter(id => id !== _id));
        }
    };

    // const handleAction = async ( action: string, _id: string) => {
    //     const res = await setHistoryAction({ action, ids: [_id]});
    //     if(res.success){
    //         toast.success(`Done action: ${action}`);
    //         updateHistories();
    //     }else{
    //         toast.error(res.msg);
    //     }
    // }

    const handleViewLog = async ( _id: string, mint: string) => {
        const res = await getLog({ _id });
        if(res.success){
            setLogData(res.data);
            setLogModalOpen(true);
            setModalCaption(mint);
        }else{
            toast.error(res.msg);
        }
    }

    const handleDeleteSelected = async () => {
        const res = await setHistoryAction({ action: 'delete', ids: checkedList});
        if(res.success){
            toast.success("successfully deleted checked items");
            updateHistories();
        }else{
            toast.error(res.msg);
        }
    }
    const handleCommandChange = (value: string) => {
        if (commands.includes(value)) {
            setCommands(commands.filter(command => command !== value));
        } else {
            setCommands([...commands, value]);
        }
    }

    const handleStatusChange = (value: string) => {
        if (statuses.includes(value)) {
            setStatuses(statuses.filter(status => status !== value));
        } else {
            setStatuses([...statuses, value]);
        }
    }

    return (
        <Box pt={2} sx={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <LogModal open={logModalOpen} logData={logData} onClose={()=>setLogModalOpen(false)} caption={modalCaption}/>
            <Box display='flex' justifyContent={'space-between'} px={4}>
                <Box sx={{display: 'flex', justifyContent: 'center'}} gap={1}>
                    {commandList.map((cmd) => (
                        <ToggleButton key={cmd} sx={{padding: '4px 12px', color: 'divider'}} value={cmd} selected={commands.includes(cmd)} onChange={() => handleCommandChange(cmd)}>
                            <Typography>{cmd}</Typography>
                        </ToggleButton>
                    ))}
                </Box>
                <Box>
                    <Button variant='outlined' color='secondary' onClick={handleDeleteSelected}>Delete Selected</Button>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}} gap={1}>
                    {statusList.map((cmd) => (
                        <ToggleButton key={cmd} sx={{padding: '4px 12px', color: 'divider'}} value={cmd} selected={statuses.includes(cmd)} onChange={() => handleStatusChange(cmd)}>
                            <Typography>{cmd}</Typography>
                        </ToggleButton>
                    ))}
                </Box>
            </Box>
            <Box mt={1} pb={1} sx={{display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'auto', flex: 1,
                '& table': {
                    border: '1px solid blue',
                    borderColor: 'divider',
                    borderRadius: '12px 12px 0 0',
                    borderBottom: 'none',
                    thead: {
                        height: '50px',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#1a1a1a',
                        borderRadius: '12px',
                        zIndex: 1,
                    },
                    th: {
                        color: 'text.disabled',  
                    },
                    tr: {
                        height: '50px',
                    },
                    td: {
                        textAlign: 'center',
                        borderBottom: '1px solid blue',
                        borderBottomColor: 'divider',
                        
                    }
                },
             }}>
                <table>
                    <thead>
                        <tr>
                            <th><Checkbox onChange={(e)=>handleAllChecked(e.target.checked)}/></th>
                            <th>Date</th>
                            <th>cmd</th>
                            <th>Pool</th>
                            <th>NFT ID</th>
                            <th>Balance</th>
                            <th>Collected</th>
                            <th>USDT</th>
                            <th>Wasted Fee</th>
                            <th>Wallet</th>
                            <th>All Pools</th>
                            <th>Total</th>
                            <th>After Total</th>
                            <th>DIFF</th>
                            <th>Swap Minus</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {histories.map((info) => {
                            if(commands.includes(info.commandType) && statuses.includes(info.status)){
                                return (
                                    <tr key={info._id}>
                                        <td>
                                            <Checkbox checked={checkedList.includes(info._id)} onChange={(e)=>handleChecked(info._id, e.target.checked)}/>
                                        </td>
                                        <td>{formatDateTime(info.createdAt)}</td>
                                        <td>{info.commandType}</td>
                                        <td style={{maxWidth: '100px'}}>
                                            <Stack>
                                                <Typography>{info.data.symbolA}/{info.data.symbolB}</Typography>
                                                {info.data.prices &&
                                                    <Typography variant="caption" color='divider'>
                                                        {displayWithNonZeroDecimals(info.data.prices[info.data.symbolA], 2) || '_'} / {displayWithNonZeroDecimals(info.data.prices[info.data.symbolB], 2) || '_'}
                                                    </Typography>
                                                }
                                            </Stack>
                                        </td>
                                        <td style={{maxWidth: '100px'}}>
                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                <Typography>{shortenAddress(info.data.positionMint, 4)}</Typography>
                                                {info.data.oldPositionMint && <Typography variant="caption" color='divider'>{shortenAddress(info.data.oldPositionMint, 4)}</Typography>}
                                            </Box>
                                        </td>
                                        <td>
                                            ${displayWithNonZeroDecimals(info.data.balance, 1)}
                                        </td>
                                        <td>${displayWithNonZeroDecimals(info.data.totalYield, 1)}</td>
                                        <td>{displayWithNonZeroDecimals(info.data.converted, 1)}</td>
                                        <td>${displayWithNonZeroDecimals(info.data.wastedFeeUsd, 1)}</td>
                                        <td>${displayWithNonZeroDecimals(info.data.walletBalance, 1)}</td>
                                        <td>${displayWithNonZeroDecimals(info.data.poolBalance, 1)}</td>
                                        <td>${displayWithNonZeroDecimals(info.data.beforeTotalBalance, 1)}</td>
                                        <td>${displayWithNonZeroDecimals(info.data.afterTotalBalance, 1)}</td>
                                        <td>{displayWithNonZeroDecimals(info.data.difference, 1)}</td>
                                        <td>{displayWithNonZeroDecimals(info.data.swapMinus, 1)}</td>
                                        <td>
                                            <Typography color={textColor[info.status]}>
                                                <Stack>
                                                    <Typography>{info.status}</Typography>
                                                    <Typography variant="caption" color='divider'>{info.data.rebalance?.completeReason ?? ''}</Typography>
                                                </Stack>
                                            </Typography>
                                        </td>
                                        <td>
                                            <Box sx={{display: 'flex', justifyContent: 'center'}} gap={1}>
                                                <Button color='primary' onClick={()=>handleViewLog(info._id, info.data.positionMint)}>View Log</Button>
                                                {/* {info.status === 'failed' && 
                                                <Button color='secondary' onClick={()=>handleAction('redo', info._id)}>Redo</Button>
                                                } */}
                                            </Box>
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
            </Box>
            <Box sx={{
                height: 40,
                bgcolor: '#000',
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid #2c2c2c',
                px: 2,
            }}>
                <Typography>Total Earned (USDT): {displayWithNonZeroDecimals(converted, 1)}</Typography>
            </Box>
        </Box>
    );
}